import { tranStr } from "../../utils/translation";

export const MASTER_BENCHMARKING = {
  TITLE_PLACEHOLDER: tranStr("<untitled>"),
  TITLE_PLACEHOLDER_HINT: tranStr("Please enter a name"),
  KEYS: {
    DISCLAIMER_LOCAL_STORAGE_KEY: "benchmarking-disclaimer-user-acceptance",
  },
  FLAGS: {
    SAVE_PENDING: "flag_save_pending",
    MARKED_FOR_DELETION: "flag_marked_for_deletion",
    DELETED: "flag_deleted",
  },
  WARNINGS: {
    DISCLAIMER_NOT_ACCEPTED: tranStr("Please acknowledge the disclaimer"),
    REQUIRED_FIELDS_MISSING: `${tranStr("These fields are required")}:`,
    REQUIRED_BENCHMARKS_MISSING: tranStr("Please select atleast 2 projects"),
    CONFIGURATION_INCOMPLETE: tranStr("Project configuration incomplete"),
    BENCHMARK_SELECTION_INCOMPLETE: tranStr("Project selection incomplete"),
    ITEM_NOT_SELECTED_FOR_LOAD_OR_SAVE: (
      <span>
        {tranStr("Please select a configuration to load,")}
        <br />
        {tranStr("or select the current configuration to save.")}
      </span>
    ),
    ITEM_DELETE_CONFIRMATION: (
      <b>
        {tranStr(
          "This Benchmark Analysis will be deleted for you and all other users, and cannot be undone."
        )}
        <br />
        {tranStr("Are you sure?")}
      </b>
    ),
    ITEM_TITLE_NOT_SPECIFIED: tranStr(
      "Please enter a name to save the selection"
    ),
  },
  SECTIONS: {
    CURRENT_SELECTION: {
      LABEL: tranStr("Save to Collection"),
      TYPE: "current-selection",
    },
    PREVIOUSLY_SAVED: {
      LABEL: tranStr("Load from Collection"),
      TYPE: "previously-saved",
    },
  },
  ICONS: {
    APPLY: "fas fa-download",
  },
  ACTIONS: {
    RENAME: {
      TOOLTIP: "",
      LABEL: "",
      ICON: "fas fa-pen",
      ICON_LARGE: "fas fa-pen fa-lg",
    },
    DELETE: {
      TOOLTIP: "",
      LABEL: "",
      ICON: "far fa-trash-alt",
    },
    DELETE_PERMANENT: {
      TOOLTIP: "",
      LABEL: "Delete",
      ICON: "far fa-trash-alt",
    },
    CANCEL: {
      TOOLTIP: "",
      LABEL: tranStr("Cancel"),
      ICON: "fas fa-ban",
    },
    SAVE: {
      TOOLTIP: "",
      LABEL: tranStr("Save"),
      ICON: "fas fa-save",
    },
    LOAD: {
      TOOLTIP: "",
      LABEL: tranStr("Load"),
      ICON: "fas fa-caret-square-up",
    },
  },
  TOOLTIPS: {
    GLOBAL_BENCHMARKING_TOGGLE: tranStr(
      "Global Benchmarking enables comparison across different regions. It uses Global Location Factors and converts currencies as defined in the parameters."
    ),
    LOCAL_REGION_AREA_TOGGLE: tranStr(
      "Local Region Area changes the analysis from a cost/GIA basis to the project's cost/local region area (ie cost/MW, cost/bed, cost/room)"
    ),
  },
};
