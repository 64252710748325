import { escalateFer } from "./escalateCurrency.functions";
import { escalateTfi } from "./escalateLocality.functions";
import { escalateTpi } from "./escalateInflation.functions";

export function escalation(escalationData) {
  const { costData } = escalationData;

  const { benchmarks } = costData;

  // Escalate Costs for Benchmarks
  benchmarks.forEach((benchmark) => {
    const bm = benchmark;

    const key = "construction_cost_rate"; // or GIA based on localRegionFactor
    const costObj = bm;
    escalate(escalationData, bm, costObj, key);

    // Rows
    bm.rows.forEach((row) => {
      const bmRowCostObj = row;

      // Cost / m2 GFA (Local Region Area)
      const rowKey = "local_region_rate"; // or GIA based on localRegionFactor (localRegionFactor ? row.local_region_rate : row.gia_rate)
      escalate(escalationData, bm, bmRowCostObj, rowKey);

      // Elemental Unit Rate
      const rowElementKey = "rate";
      escalate(escalationData, bm, bmRowCostObj, rowElementKey);
    });

    // Categories
    bm.category_groups?.forEach((group) => {
      group.categoryItems.forEach((item) => {
        const key = "value";
        const costObj = item;
        escalate(escalationData, bm, costObj, key);
      });
    });
  });
}

function escalate(cData, b, costObject, key) {
  const rate = costObject[key];
  // Exchange Escalation
  const { ferRate, ferPercent } = escalateFer(cData, b, rate);
  costObject["exchanged_" + key] = ferRate;
  costObject["percent_fer_escalated_" + key] = ferPercent;

  // Locality Escalation
  const cEscalated = costObject["exchanged_" + key];
  const { lfiRate, lfiPercent } = escalateTfi(cData, b, cEscalated);
  costObject["locality_escalated_" + key] = lfiRate;
  costObject["percent_lfi_escalated_" + key] = lfiPercent;

  // Inflation Escalation
  const lEscalated = costObject["locality_escalated_" + key];
  const { tpiRate, tpiPercent } = escalateTpi(cData, b, lEscalated);
  costObject["escalated_" + key] = tpiRate;
  costObject["percent_tpi_escalated_" + key] = tpiPercent;
}

// Project Dashboard
export function calculateEscalationShallow(TPI, benchmarks, futureYear) {
  // Escalation
  const escalationData = {
    costData: {
      comparisonBenchmarks: benchmarks,
    },
    inflationData: {
      TPI: TPI,
      futureYear: futureYear,
    },
  };

  // Escalate High level benchmark costs
  benchmarks?.forEach((b) => {
    let rate = b.construction_cost_rate;
    const { tpiRate } = escalateTpi(escalationData, b, rate);
    let escalated_rate = tpiRate;

    b.escalated_construction_cost_rate = escalated_rate;
    b.displayed_construction_cost_rate = escalated_rate;
  });
}

export function toggleEscalation(isEscalated, benchmarks) {
  ///////////////////
  //Display Costs
  ///////////////////

  const bms = benchmarks;

  // Determine Display Cost
  if (isEscalated) {
    bms.forEach((b) => {
      // High Level Costs
      b.displayed_construction_cost_rate = b.escalated_construction_cost_rate;

      // Row Costs
      b.rows.forEach((row) => {
        row.displayed_local_region_rate = row.escalated_local_region_rate;
        row.displayed_rate = row.escalated_rate;
      });

      // Category Costs
      b.category_groups?.forEach((group) => {
        group.categoryItems.forEach((item) => {
          item.displayed_value = item.escalated_value;
        });
      });
    });
  } else {
    bms.forEach((b) => {
      // High Level Costs
      b.displayed_construction_cost_rate = b.construction_cost_rate;

      // Row Costs
      b.rows.forEach((row) => {
        row.displayed_local_region_rate = row.local_region_rate;
        row.displayed_rate = row.rate;
      });

      // Category Costs
      b.category_groups?.forEach((group) => {
        group.categoryItems.forEach((item) => {
          item.displayed_value = item.value;
        });
      });
    });
  }
}
