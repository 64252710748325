import React, { useState } from "react";
import PropTypes from "prop-types";

import isEmpty from "../../validation/is-empty";
import { comma, FormatDate } from "../../helpers/common.functions";
import Modal from "../modal/Modal";
import BenchmarkPage from "../benchmarking/BenchmarkPage.components";

/// props validation | SQ(javascript:S6774)
ProjectRow.propTypes = {
  benchmark: PropTypes.object,
  isProject: PropTypes.bool,
  unitOfMeasure: PropTypes.string,
  country: PropTypes.string,
  currencySymbol: PropTypes.string,
  cbs: PropTypes.object,
  localRegionFactor: PropTypes.bool,
  isGlobalBenchmarking: PropTypes.bool,
};
///
export default function ProjectRow(props) {
  const { benchmark } = props;
  const { isProject } = props;
  const { unitOfMeasure } = props;
  const { country } = props;
  const { currencySymbol } = props;
  const { cbs } = props;
  const { localRegionFactor } = props;
  const { isGlobalBenchmarking } = props;

  const [modal, setModal] = useState(false);

  const [isProjectWarningHovered, setIsProjectWarningHovered] = useState(false);
  const [isLocationWarningHovered, setIsLocationWarningHovered] =
    useState(false);

  let rowType = "bdt-row";
  if (isProject) {
    rowType = "bdt-project-row";
  }

  if (isEmpty(benchmark)) {
    return null;
  }
  let unit = " " + unitOfMeasure;

  let projectwarning = "";
  let locationwarning = "";

  if (!isProject && benchmark.country !== country && !isGlobalBenchmarking) {
    projectwarning = (
      <WarningTooltip
        className={"project-warning-icon"}
        isHovered={isProjectWarningHovered}
        setIsHovered={setIsProjectWarningHovered}
        message={
          "Comparison projects have not been adjusted for location, since location factors are not applied across countries."
        }
      />
    );
  }

  if (
    isGlobalBenchmarking &&
    (benchmark.globalFactorNotFoundForBenchmarkLocation ||
      benchmark.globalFactorNotFoundForProjectLocation)
  ) {
    let message = benchmark.globalFactorNotFoundForBenchmarkLocation
      ? "Global factor cannot be found for this project's city."
      : "Global factor cannot be found for the selected city.";

    projectwarning = (
      <WarningTooltip
        className={"project-warning-icon"}
        isHovered={isProjectWarningHovered}
        setIsHovered={setIsProjectWarningHovered}
        message={message}
      />
    );
  }

  if (!isProject && benchmark.locationWarning) {
    locationwarning = (
      <WarningTooltip
        className={"location-warning-icon"}
        isHovered={isLocationWarningHovered}
        setIsHovered={setIsLocationWarningHovered}
        message={"Locality factor cannot be found for this project."}
      />
    );
  }

  return (
    <div
      className={rowType}
      onClick={() => {
        // dev only setModal(!modal);
      }}
    >
      <div className="bdt-row-name-cell">
        {bold(isProject, benchmark.project_name)} {projectwarning}{" "}
        {locationwarning}
      </div>
      <div className="bdt-base-date">
        {bold(isProject, FormatDate(benchmark.base_date))}
      </div>
      <div className="bdt-gfa">
        {bold(
          isProject,
          (localRegionFactor
            ? comma(benchmark.local_region_area)
            : comma(benchmark.gia)) +
            " " +
            unit
        )}
      </div>
      <div className="bdt-cost">
        {bold(
          isProject,
          currencySymbol + comma(Math.round(benchmark.construction_cost_rate))
        )}
      </div>
      <div className="bdt-cost">
        {bold(
          isProject,
          currencySymbol + comma(benchmark.escalated_construction_cost_rate)
        )}
      </div>
      <div className="bdt-procurement">
        {bold(isProject, benchmark.procurement_method)}
      </div>
      <div className="bdt-type">{bold(isProject, benchmark.project_type)}</div>
      <div className="bdt-quality">{bold(isProject, benchmark.quality)}</div>
      <div className="bdt-sector">{bold(isProject, benchmark.sector)}</div>
      <Modal
        // Modal Props
        title={"Benchmark"}
        Component={BenchmarkPage}
        modal={modal}
        setModal={setModal}
        // Component Props
        benchmark={benchmark}
        cbs={cbs}
      />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
WarningTooltip.propTypes = {
  className: PropTypes.string,
  isHovered: PropTypes.bool,
  setIsHovered: PropTypes.func,
  message: PropTypes.any,
};
///
function WarningTooltip(props) {
  const { className } = props;
  const { isHovered, setIsHovered } = props;
  const { message } = props;

  return (
    <span
      role="button"
      className={`${className} ${isHovered ? "show-tooltip" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <i className="fas fa-exclamation-triangle" />
      <span className="tooltip">{message}</span>
    </span>
  );
}

function bold(isProject, value) {
  if (isProject) {
    return <b>{value}</b>;
  } else {
    return value;
  }
}
