import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      heading: tranStr("Project Name"),
      key: "project_name_short",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Construction Cost Rate"),
      key: "construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
    {
      heading: tranStr("Currency %"),
      key: "percent_fer_escalated_construction_cost_rate",
      type: "PERCENT",
      width: 100,
    },
    {
      heading: tranStr("Currency Rate"),
      key: "exchanged_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
    {
      heading: tranStr("Locality %"),
      key: "percent_lfi_escalated_construction_cost_rate",
      type: "PERCENT",
      width: 100,
    },
    {
      heading: tranStr("Locality Rate"),
      key: "locality_escalated_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
    {
      heading: tranStr("Inflation %"),
      key: "percent_tpi_escalated_construction_cost_rate",
      type: "PERCENT",
      width: 100,
    },
    {
      heading: tranStr("Inflation Rate"),
      key: "escalated_construction_cost_rate",
      type: "NUMBER",
      width: 100,
    },
  ];
}
