import PropTypes from "prop-types";

import {
  buildDateFromYear,
  extractYearFromDate,
} from "../../helpers/common.functions";

export default class BenchmarkCreateAnalysisConfiguration {
  true; // bypass SonarLint bug 'Unexpected class with only a constuctor'

  constructor({
    sector = "",
    subsector = "",
    quality = "",
    projectType = "",
    currency = "",
    unitOfMeasure = "",
    currencySymbol = "",
    region = "",
    country = "",
    city = "",
    baseYear = "",
    baseDate = "",
    latitude = "0",
    longitude = "0",
    currencyFactor = false,
    localRegionFactor = false,
  } = {}) {
    this.sector = sector || "";
    this.subsector = subsector || "";
    this.quality = quality || "";
    this.projectType = projectType || "";
    this.currency = currency || "";
    this.unitOfMeasure = unitOfMeasure || "";
    this.currencySymbol = currencySymbol || "";
    this.region = region || "";
    this.country = country || "";
    this.city = city || "";
    this.baseYear = "";
    this.baseDate = "";
    this.latitude = latitude || "0";
    this.longitude = longitude || "0";
    this.latitude_longitude = [this.latitude, this.longitude];
    this.currencyFactor = currencyFactor || false;
    this.localRegionFactor = localRegionFactor || false;

    // one of baseYear or baseDate must be provided
    // if baseYear is provided, build baseDate
    if (baseYear) {
      this.baseYear = baseYear;
      this.baseDate = buildDateFromYear({ year: baseYear });
    }
    // if baseDate is provided, extract baseYear
    // prefer the value in baseDate as the source of truth
    if (baseDate) {
      this.baseDate = baseDate;
      this.baseYear = extractYearFromDate({ date: baseDate });
    }
  }
}

/// props validation | SQ(javascript:S6774)
BenchmarkCreateAnalysisConfiguration.propTypes = {
  sector: PropTypes.string,
  subsector: PropTypes.string,
  quality: PropTypes.string,
  projectType: PropTypes.string,
  currency: PropTypes.string,
  unitOfMeasure: PropTypes.string,
  currencySymbol: PropTypes.string,
  region: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  baseYear: PropTypes.string,
  baseDate: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  latitude_longitude: PropTypes.array,
  currencyFactor: PropTypes.bool,
  localRegionFactor: PropTypes.bool,
};
///
